import React from "react";
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import BorrowerDashboard from "./pages/BorrowerDashboard";
import Register from './pages/Register';
import RegisterBorrower from './pages/RegisterBorrower';
import HelpCenter from './pages/HelpCenter';
import ContactUs from "./pages/ContactUsCenter";
import ThankYou from "./pages/ThankYou";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import BorrowerInvestment from "./pages/BorrowerInvestment";
import BorrowerAddInvoice from "./pages/BorrowerAddInvoice";
import BorrowerVerification from "./pages/BorrowerVerification";
import PrivateRoute from "./PrivateRoute";

import 'bootstrap/dist/css/bootstrap.css';
import './css/form.css';
import './app.css';

function App() {
    return (
        <Router>
            <Switch>
                {/*<Route exact path="/login" component={Login} />*/}
                <Route exact path="/help-center" component={HelpCenter} />
                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/thank-you" component={ThankYou} />
                {/*<Route exact path="/borrower/register" component={RegisterBorrower} />*/}
                <Route exact path="/register" component={Register} />
                {/*<Route exact path="/borrower/verification" component={BorrowerVerification} />*/}
                <PrivateRoute exact path="/">
                    <BorrowerDashboard />
                </PrivateRoute>
                {/*<PrivateRoute exact path="/borrower/investments">*/}
                    {/*<BorrowerInvestment />*/}
                {/*</PrivateRoute>*/}
                {/*<PrivateRoute exact path="/borrower/invoice/add">*/}
                    {/*<BorrowerAddInvoice />*/}
                {/*</PrivateRoute>*/}
                <Route component={NotFound} />
            </Switch>
        </Router>
    )
}

export default App;