import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios/index";

import Sidebar from "../components/layout/sidebar/Sidebar";
import ContactUsWrapper from "../components/layout/wrapper/ContactUsWrapper";
import config from "../config";

function ContactUs(props) {
    const [isLoading, setLoading] = useState(false);
    const [getData, setData] = useState({});
    const [message, setMessage] = useState({});

    const { register, handleSubmit, watch, errors } = useForm();

    const onSubmit = (data) => {
        setLoading(true);
        setData(data);
    };

    useEffect(function () {

        if (Object.keys(getData).length > 0) {
            setTimeout(function () {
                axios.post('/api/v1/contact-us', getData, { headers: {'Content-Type': 'application/json'}})
                    .then(function (response) {
                        setLoading(false);
                        setMessage({success:"Message has been sent!"});
                    })
                    .catch(function (error) {
                        setLoading(false);
                        setMessage({error: "Message can not be sent!"})
                    })
                    .finally(function () {

                    });
            }, 2000);
        }

    }, [getData, props.history]);

    return (
        <div>
            <Sidebar
                visibility={false}
                percent={0}
                history={props.history}
                menu={config.infoSidebarMenu()}
            />
            <ContactUsWrapper
                history={props.history}
                form={{register, handleSubmit, watch, errors, onSubmit}}
                isLoading={isLoading}
                message={message}
            />
        </div>
    );
}

export default ContactUs;
