export default (state = {}, { type, payload }) => {
    switch (type) {
        case 'GET_USER':
            const data = Object.assign({logged: false}, payload.user);

            return {
                ...state, ...data,
            };
        case 'CLEAR_USER':
            return {};
        default:
            return state;
    }
};