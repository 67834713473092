import React, {useEffect} from 'react';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/layout/sidebar/Sidebar";
import BorrowerVerificationWrapper from "../components/layout/wrapper/BorrowerVerificationWrapper";

import config from "../config";
import userActions from "../actions/user";
import Loader from "../components/layout/Loader";

function BorrowerVerification({...rest}) {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(function () {
        dispatch(userActions.initUser());
    }, []);

    if (Object.keys(user).length === 0) {
        return <Loader/>
    }

    if (!user.logged) {
        return <Route
            {...rest}
            render={({ location }) =>
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            }
        />
    }

    return (
        <div>
            <Sidebar
                visibility={false}
                history={history}
                menu={config.borrowerVerificationMenu()}
            />
            <BorrowerVerificationWrapper
                history={history}
            />
        </div>
    )
}

export default BorrowerVerification;