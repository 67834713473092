import React, { useState, useEffect } from "react";
import {useForm} from "react-hook-form/dist/index";
import axios from 'axios';

import Sidebar from "../components/layout/sidebar/Sidebar";
import RegisterWrapper from "../components/layout/wrapper/RegisterWrapper";

import {progressBar} from "../helper";
import config from "../config";
import kycActions from "../actions/kyc";
import {useDispatch} from "react-redux";

function Register(props) {
    const dispatch = useDispatch();

    const [getCurrentStep, setCurrentStep] = useState(1);
    const [getCompletedSteps, setCompletedSteps] = useState([]);
    const [getBorrowerData, setBorrowerData] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isCompleteRegistration, setCompleteRegistration] = useState(false);

    const onInputchange = (event) => {

        if (event.target.type !== 'checkbox'|| event.target.type !== 'radio') {
            if (event.target.value.length > 0) {
                event.target.previousSibling.className = 'show';
                event.target.parentElement.className += ' has-success';
            } else {
                event.target.previousSibling.classList.remove("show");
                event.target.parentElement.classList.remove("has-success");
            }
        }

        setBorrowerData({...getBorrowerData, [event.target.name]: event.target.value})
    };

    /* Form`s config */
    const {
        register: registerUserAccount,
        handleSubmit: handleSubmitUserAccount,
        watch: watchUserAccount,
        errors: errorsUserAccount
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });
    const {
        register: registerCompanyInfo,
        handleSubmit: handleSubmitCompanyInfo,
        watch: watchCompanyInfo,
        errors: errorsCompanyInfo
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });
    const {
        register: registerKYC,
        handleSubmit: handleSubmitKYC,
        watch: watchKYC,
        errors: errorsKYC,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });


    /* User Account submit form */
    const onSubmitUserAccount = () => {

        if (!getCompletedSteps.includes(1)) {
            setCompletedSteps([...getCompletedSteps, 1]);
        }

        setCurrentStep(2);
        progressBar();
    };

    /* Company Information submit form */
    const onSubmitCompanyInfo = () => {

        if (!getCompletedSteps.includes(2)) {
            setCompletedSteps([...getCompletedSteps, 2]);
        }

        setCurrentStep(3);
        progressBar();
    };

    /* KYC submit form */
    const onSubmitKYC = (data) => {

        if (!getCompletedSteps.includes(3)) {
            setCompletedSteps([...getCompletedSteps, 3]);
        }

        setLoading(true);
        axios.post(config.getUrl() + '/api/v1/register', {...getBorrowerData, kyc: data}, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                mode: 'no-cors',
            }
        })
            .then(function (response) {
                setCurrentStep(4);
                setCompleteRegistration(false);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                setLoading(false);
            });
    };

    /* Email Verification confirmation */
    const submitEmailVerification = () => {
        setLoading(true);

        setTimeout(function () {
            setCompletedSteps([]);
            setBorrowerData({});
            setCurrentStep(1);
            setCompleteRegistration(true);
            props.history.push('/thank-you');
        }, 1000);
    };

    /* Form`s props */
    const formUserAccount = {registerUserAccount, handleSubmitUserAccount, watchUserAccount, errorsUserAccount, onSubmitUserAccount};
    const formCompanyInfo = {registerCompanyInfo, handleSubmitCompanyInfo, watchCompanyInfo, errorsCompanyInfo, onSubmitCompanyInfo};
    const formKYC = {registerKYC, handleSubmitKYC, watchKYC, errorsKYC, onSubmitKYC};

    useEffect(function () {
       if (getCurrentStep === 3) {
           dispatch(kycActions.getKYC());
       }
    }, [getCurrentStep]);

    return (
        <div>
            <Sidebar
                visibility={true}
                history={props.history}
                menu={config.infoSidebarMenu()}
            />
            <RegisterWrapper
                history={props.history}
                userData={getBorrowerData}
                progressBarData={{currentStep: getCurrentStep, completedSteps: getCompletedSteps}}
                form={
                    {
                        userAccount: formUserAccount,
                        companyInfo: formCompanyInfo,
                        kyc: formKYC,
                        emailVerification: submitEmailVerification
                    }
                }
                formStep={{current: getCurrentStep, completed: getCompletedSteps}}
                isLoading={isLoading}
                setCurrentStep={setCurrentStep}
                onInputchange={onInputchange}
            />
        </div>
    );
}

export default Register;
