import React from 'react';

import '../../../css/progress_step_bar.css';

const ProgressStepBar = (props) => {

    const completedSteps = props.progressBarData.completedSteps;
    const currentStep = props.progressBarData.currentStep;

    const stepsInit = (
        <ul className="progressbar">
            {props.config.map((config, key) => {
                const index = key + 1;
                const width = "calc(100% / " + props.config.length + ")";

                let className = completedSteps.includes(index) ? 'active' : '';
                className += currentStep === index ? ' current' : '';

                return  <li key={index} className={className} style={{width: width}}>{config.step}</li>
            })}
        </ul>
    );

    return (
        <div className="register-progressbar">
            {stepsInit}
        </div>
    );
};

export default ProgressStepBar;