export default (state = [], { type, payload }) => {
    switch (type) {
        case 'GET_INVOICE':
            return [...payload];
        case 'ACCEPT_INVOICE':
            return [];
        case 'REJECT_INVOICE':
            return [];
        default:
            return state;
    }
};