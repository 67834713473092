import React from 'react';
import {BrowserRouter as Router, Link} from "react-router-dom";

const Footer = (props) => {

    const pathname = props.history.location.pathname;

    return (
        <div className="footer my-5">
            <Router>
                <ul className="list-inline">
                    {/*<li className={`list-inline-item mr-3 ${pathname === '/help-center' ? 'active' : ''}`}>*/}
                        {/*<Link className="text-orange" onClick={() => props.history.push('/help-center')} to={'/help-center'}>Help Center (FAQ)</Link>*/}
                    {/*</li>*/}
                    {/*<li className={`list-inline-item mr-3 ${pathname === '/contact-us' ? 'active' : ''}`}>*/}
                        {/*<Link className="text-orange" onClick={() => props.history.push('/contact-us')} to={'/contact-us'}>Contact Us</Link>*/}
                    {/*</li>*/}
                    <li className="list-inline-item mr-3">
                        <a className="text-orange" href="https://www.floatlending.com/contact">Contact us</a>
                    </li>
                    <li className="list-inline-item mr-3">
                        <a className="text-orange" href="https://www.floatlending.com">FLOAT Lending Website</a>
                    </li>
                </ul>
            </Router>

            <p>
                FLOAT Lending har tillstånd från Finansinspektionen att tillhandahålla betaltjänster i Sverige
                <br />© 2020 FLOAT Lending Aktiebolag
            </p>
        </div>
    );
};

export default Footer;