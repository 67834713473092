import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from "react-router-dom";

import InfoSidebar from "./InfoSidebar";

import logo from '../../../images/logo.svg';

import '../../../css/sidebar.css';

const Sidebar = (props) => {
    return (
        <div className="sidebar bg-purple">
            <div className="text-center">
                <Link to='/'>
                    <Image src={logo} />
                </Link>
            </div>
            <InfoSidebar
                visibility={props.visibility}
                menu={props.menu}
                history={props.history}
            />
        </div>
    );
};

export default Sidebar;