import React from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-solid-svg-icons/index";

import Nav from "../nav/Nav";
import Footer from "../Footer";

const ContactUsWrapper = (props) => {

    const lead = "Please use the form below to contact FLOAT if you have any further questions or concerns";

    const floatLabels = (event) => {
        if (event.target.value.length > 0) {
            event.target.previousSibling.className = 'show';
            event.target.parentElement.className += ' has-success';
        } else {
            event.target.previousSibling.classList.remove("show");
            event.target.parentElement.classList.remove("has-success");
        }
    };

    return (
        <div className="wrapper">
            <div className="wrapper-container">
                <Nav title="Contact Us" lead={lead}/>

                <div className="bg-white px-3 py-5">
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <Form onSubmit={props.form.handleSubmit(props.form.onSubmit)}>
                                <Form.Group controlId="formName" className="float-labels">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control
                                        onChange={(event) => floatLabels(event)}
                                        className="custom-input"
                                        name="name"
                                        type="text"
                                        placeholder="Your Name"
                                        ref={props.form.register({ required: true })}
                                    />
                                    {props.form.errors.name && <Form.Text className="text-danger">This field is required!</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="formEmail" className="float-labels">
                                    <Form.Label>Your Email</Form.Label>
                                    <Form.Control
                                        onChange={(event) => floatLabels(event)}
                                        className="custom-input"
                                        name="email"
                                        type="email"
                                        placeholder="Your Email"
                                        ref={props.form.register({
                                            required: true,
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                            }
                                        })}
                                    />
                                    {props.form.errors.email && <Form.Text className="text-danger">This field is required!</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="formMessage" className="float-labels textarea-float-labels">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        onChange={(event) => floatLabels(event)}
                                        className="custom-textarea"
                                        placeholder="Message"
                                        as="textarea"
                                        rows="5"
                                        name="message"
                                        ref={props.form.register({ required: true })}
                                    />
                                    {props.form.errors.message && <Form.Text className="text-danger">This field is required!</Form.Text>}
                                </Form.Group>
                                {props.isLoading ? (
                                    <div className="text-center">
                                        <FontAwesomeIcon size="3x" color="#ff7001" className="spinner" icon={Icons.faSync} />
                                    </div>
                                ):(
                                    <div className="text-right">
                                        <Button className="btn-submit" type="submit">Submit</Button>
                                    </div>
                                )}
                                {props.message && (
                                    props.message.success && <div className="my-3">
                                        <Alert variant="success">{props.message.success}</Alert>
                                    </div>
                                )}
                                {props.message && (
                                    props.message.error &&  <div className="my-3">
                                        <Alert variant="danger">{props.message.error}</Alert>
                                    </div>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </div>

                <Footer history={props.history}/>
            </div>
        </div>
    );
};

export default ContactUsWrapper;