import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index";
import * as Icons from '@fortawesome/free-solid-svg-icons';

function ThankYou(props) {
    return (
        <div className="text-green-secondary d-flex align-items-center justify-content-center" style={{height:'100vh'}}>
            <div className="text-center">
                <FontAwesomeIcon size="7x" color="#9ED442" icon={Icons.faThumbsUp} />
                <h1 className="mt-5">Thank you</h1>
                <h5>Verification email sent. Please verify your email by clicking the link in your email.</h5>
            </div>
        </div>
    );
}

export default ThankYou;
