import React from 'react';
import { Form, Button } from 'react-bootstrap';
import {progressBar} from "../../helper";
import {useSelector} from "react-redux";
import * as Icons from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const KYC = (props) => {
    const kyc = useSelector(state => state.kyc);

    const floatLabels = (event) => {
        if (event.target.value.length > 0) {
            event.target.previousSibling.className = 'show';
            event.target.parentElement.className += ' has-success';
        } else {
            event.target.previousSibling.classList.remove("show");
            event.target.parentElement.classList.remove("has-success");
        }
    };

    const setProgressBar = () => {
        props.setCurrentStep(2);
        progressBar();
    };

    const createField = (field, key) => {
        switch (field.type) {
            case "text":
                return (
                    <Form.Group
                        key={key}
                        controlId={field.attr.name}
                        className={props.form.kyc.errorsKYC[field.attr.name] ? "has-error float-labels" : "has-success float-labels"}
                    >
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            onChange={(event) => props.onInputchange(event)}
                            className="custom-input"
                            name={field.attr.name}
                            placeholder={field.attr.placeholder}
                            type="text"
                            ref={props.form.kyc.registerKYC({
                                required: true
                            })}
                        />
                        {props.form.kyc.errorsKYC[field.attr.name] && <Form.Text className="text-danger">This field is required!</Form.Text>}
                    </Form.Group>
                );
            case "textarea":
                return (
                    <Form.Group controlId={field.attr.name} className="float-labels" key={key}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            onChange={(event) => props.onInputchange(event)}
                            className="custom-textarea"
                            placeholder={field.attr.placeholder}
                            as="textarea"
                            rows="5"
                            name={field.attr.name}
                            ref={props.form.kyc.registerKYC({
                                required: true
                            })}
                        />
                        {props.form.kyc.errorsKYC[field.attr.name] && <Form.Text className="text-danger">This field is required!</Form.Text>}
                    </Form.Group>
                );
            case "select":
                return (
                    <Form.Group
                        controlId={field.attr.name}
                        key={key}
                        className="has-success"
                    >
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            as="select"
                            className="custom-input"
                            name={field.attr.name}
                            type="text"
                            defaultValue=""
                            ref={props.form.kyc.registerKYC({ required: "select one option" })}
                        >
                            {field.options.map((option, key) => {
                                return <option key={key} value={option[0]}>{option[1]}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                );
            case "number":
                return (
                    <Form.Group
                        controlId={field.attr.name} key={key}
                        className={props.form.kyc.errorsKYC[field.attr.name] ? "has-error" : "has-success"}
                    >
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            className="custom-input"
                            name={field.attr.name}
                            type="number"
                            defaultValue="0"
                            min={0}
                            ref={props.form.kyc.registerKYC({
                                required: true,
                                min: 1
                            })}
                        />
                        {props.form.kyc.errorsKYC[field.attr.name] && <Form.Text className="text-danger">This field is required!</Form.Text>}
                    </Form.Group>
                );
            case "checkbox":
                return (
                    <Form.Group key={key}>
                        <Form.Label>{field.label}</Form.Label>
                        {field.options.map((option, key) => {
                            return (
                                <Form.Group controlId={field.attr.name + "_" + key} key={key}>
                                    <Form.Check
                                        name={field.attr.name + "_" + key}
                                        type="checkbox"
                                        label={option[0]}
                                        value={option[0]}
                                        ref={props.form.kyc.registerKYC()}
                                    />
                                </Form.Group>
                            )
                        })}
                    </Form.Group>
                );
            case "radio":
                return (
                    <Form.Group key={key}>
                        <Form.Label>{field.label}</Form.Label>
                        {field.options.map((option, key) => {
                            return (
                                <Form.Group controlId={field.attr.name + "_" + key} key={key}>
                                    {key === 0 ? (
                                        <Form.Check
                                            defaultChecked
                                            name={field.attr.name}
                                            type="radio"
                                            label={option[0]}
                                            value={option[0]}
                                            ref={props.form.kyc.registerKYC()}
                                        />
                                    ) : (
                                        <Form.Check
                                            name={field.attr.name}
                                            type="radio"
                                            label={option[0]}
                                            value={option[0]}
                                            ref={props.form.kyc.registerKYC()}
                                        />
                                    )}
                                </Form.Group>
                            )
                        })}
                    </Form.Group>
                );
            default:
                return null
        }
    };

    return (
        <div>
            <h4>KYC</h4>
            <p>Know Your Customer.</p>
            <Form key={3} onSubmit={props.form.kyc.handleSubmitKYC(props.form.kyc.onSubmitKYC)}>

                {Object.keys(kyc).map(key => {
                    return (createField(kyc[key], key))
                })}

                {props.isLoading ? (
                    <div className="text-center">
                        <FontAwesomeIcon size="3x" color="#9ED442" className="spinner" icon={Icons.faSync} />
                    </div>
                ):(
                    <div>
                        <Button className="btn-submit btn-block" type="submit">Save and continue</Button>
                        <div className="text-center mt-3">
                            <Button onClick={() => setProgressBar()} className="btn-back">Go back</Button>
                        </div>
                    </div>
                )}

            </Form>
        </div>
    );
};

export default KYC;