import React from 'react';
import ReactApexCharts from 'react-apexcharts';

const PieChart = (props) => {

    const options = {
        stroke: {
            width: 0,
        },
        legend: {
            show: false,
        },
        colors: props.colors,
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: false,
                    },
                },
            }
        },
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false,
            style: {
                colors: props.colors
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };


    return  (
        <div>
            <ReactApexCharts options={options} series={props.values} type="donut" />
        </div>
    )
};

export default PieChart;