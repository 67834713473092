import React from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Nav from "../nav/Nav";

import helpIcon from '../../../images/help.svg';
import Footer from "../Footer";

const HelpCenterWrapper = (props) => {

    const lead = "Please read our FAQ for detail information";

    return (
        <div className="wrapper">
            <div className="wrapper-container">
                <Nav title="Help Center" lead={lead}/>

                <div className="bg-white p-3 help-center">
                    <Row>
                        <Col md={2}>
                            <Image width={80} src={helpIcon} />
                        </Col>
                        <Col md={10}>
                            <h3>Borrower FAQ</h3>
                            <ul className="list-unstyled text-orange">
                                <li className="my-2">
                                    What types of fees are charged on invoices and how are they assessed?
                                </li>
                                <li className="my-2">
                                    Do I need to change my FLOAT password because of the security issue?
                                </li>
                                <li className="my-2">
                                    Pricing Calculator
                                </li>
                                <li className="my-2">
                                    Fortnox integration
                                </li>
                            </ul>
                            <hr className="hr-orange" />

                            <div className="help-central-answer">
                                <h5>What types of fees are charged on invoices and how are they assessed?</h5>
                                <p>In order to Invest through FLOAT Lending you will need a balance on your FLOAT Lending account. Please keep in mind that the minimum investment amount at FLOAT Lending is 500 SEK. We will credit your FLOAT Lending account with thetleposit as soon as we receive it. Please allow between 1-2 banking days for this processing.</p>
                            </div>
                            <div className="help-central-answer">
                                <h5>Do I need to change my FLOAT password because of the security issue?</h5>
                                <p>Yes, please do so. Make sure your password contains at least 8 characters with at least one capital letter, one number and one special character.</p>
                            </div>
                            <div className="help-central-answer">
                                <h5>Pricing Calculator</h5>
                                <p>Slide the bar to show the amount of your invoice and in how many months it is due.</p>
                                <p>The cost of selling the invoice to FLOAT is given below. This is the total cost and there are no hidden fees, it's simple and transparent.&nbsp;&nbsp;</p>
                                <p>This price is an estimate only and the final price depends on the rating of the company paying the invoice.&nbsp;</p>
                            </div>
                            <div className="help-central-answer">
                                <h5>Fortnox integration</h5>
                                <p>In order to connect your Fortnox account with FLOAT Lending, please proceed with following steps:&nbsp;</p>
                                <ol>
                                    <li>&nbsp;Login to Fortnox and select ‘Manage users’ under your account page</li>

                                    <li>&nbsp;Select ‘Add integration’</li>

                                    <li>&nbsp;In the new pop-up window find Floatlending integration and select/approve</li>

                                    <li>&nbsp;After that the API-key window will appear – copy and paste the entire API-key for invoice import to page: <Link className="text-orange" onClick={() => props.history.push('/add')} to={'/add'}>Add Invoices", tab: IMPORT</Link></li>

                                    <li>&nbsp;Click on ‘Save and continue’</li>
                                </ol>
                                <p>When steps 1-5 are complete, your Fortnox invoices will be listed in FLOAT admin panel, ready to be imported. After that you can select one at the time, or all listed invoices, in order to import them to FLOAT, by clicking on ‘Import selected’ button. Once the import is done, the system will show the confirmation message on the screen. All imported invoices can be seen on page ‘<Link className="text-orange" onClick={() => props.history.push('/invoice')} to={'/invoice'}>My invoices</Link>’.</p>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Footer history={props.history}/>
            </div>
        </div>
    );
};

export default HelpCenterWrapper;