import React from "react";
import { useHistory } from 'react-router-dom';

import Sidebar from "../components/layout/sidebar/Sidebar";
import NotFoundWrapper from "../components/layout/wrapper/NotFoundWrapper";

import config from "../config";

function NotFound() {
    let history = useHistory();

    return (
        <div>
            <Sidebar
                visibility={false}
                percent={0}
                history={history}
                menu={config.infoSidebarMenu()}
            />
            <NotFoundWrapper
                history={history}
            />
        </div>
    );
}

export default NotFound;
