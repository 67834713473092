export function progressBar() {
    setTimeout(function () {
        const activeEl = document.querySelectorAll(".register-progressbar .progressbar li.active");
        const lastActiveEl = activeEl[activeEl.length - 1];

        if (lastActiveEl) {
            if (lastActiveEl.nextElementSibling) {
                lastActiveEl.classList += ' last';

                if (lastActiveEl.nextElementSibling.classList.contains("current")) {
                    lastActiveEl.classList.remove("last");
                }
            } else {
                lastActiveEl.classList += ' last';
            }
        }
    }, 20);
}