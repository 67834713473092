import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-solid-svg-icons/index";

const Loader = () => {
    return (
        <div className="loader">
            <FontAwesomeIcon size="3x" color="#ff7001" className="spinner" icon={Icons.faSync} />
        </div>
    )
};

export default Loader;