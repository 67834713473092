import React from "react";
import { useHistory } from 'react-router-dom';

import Sidebar from "../components/layout/sidebar/Sidebar";
import BorrowerDashboardWrapper from "../components/layout/wrapper/BorrowerDashboardWrapper";

import config from "../config";

function BorrowerDashboard() {
    let history = useHistory();

    return (
        <div>
            <Sidebar
                visibility={false}
                history={history}
                menu={config.borrowerMenu()}
            />
            <BorrowerDashboardWrapper
                history={history}
            />
        </div>
    );
}

export default BorrowerDashboard;
