import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-solid-svg-icons/index";

const EmailVerification = (props) => {
    return (
        <div className="text-center email-verification-wrapper">
            <FontAwesomeIcon size="5x" color="#9ed442" icon={Icons.faCheck} />
            <h1 className="my-5">Email verification pending</h1>
            <p className="my-5">{props.userData.firstName}, you are one step away from getting your account. Please verify your email address by following the link in email we sent to <strong>{props.userData.email}</strong>.</p>
            {props.isLoading ? (
                <FontAwesomeIcon size="3x" color="#9ED442" className="spinner" icon={Icons.faSync} />
            ):(
                <button onClick={props.form.emailVerification} type="button" className="btn btn-block btn-submit text-white">Ok</button>
            )}
        </div>
    )
};

export default EmailVerification;