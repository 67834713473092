import React from 'react';
import {Card, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

import Nav from "../nav/Nav";
import Footer from "../Footer";

import '../../../css/dashboard.css';
import ColumnChart from "../../charts/ColumnChart";
import PieChart from "../../charts/PieChart";

const BorrowerDashboardWrapper = (props) => {

    const value = [2000, null, null, 1000, 2000, null, null];
    const colors = ['#d55035', '#a2c663', '#a2c663', '#a2c663', '#a2c663', '#a2c663', '#a2c663'];
    const xAxisValues = ['Overdue', 1, 2, 3, 4, 5, 6];

    return (
        <div className="wrapper">
            <div className="wrapper-container">
                <Nav
                    title="Dashboard"
                    lead=""
                />

                <div className="row mx-n2">
                    <div className="col-xl-7 px-2 mb-3">
                        <Card className="box">
                            <Card.Header className="text-uppercase">CREDIT LIMIT</Card.Header>
                            <Card.Body>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <PieChart
                                            colors={['#d55035', '#9fc46e', '#90b7d5']}
                                            values={[44, 55, 41]}
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="row align-items-center mb-3">
                                            <div className="col-12">
                                                <p><span className="square blue"></span>Credit Used</p>
                                            </div>
                                            <div className="col-12">
                                                <h5>37.200 USD</h5>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-12">
                                                <p><span className="square green"></span>Credit Available</p>
                                            </div>
                                            <div className="col-12">
                                                <h5>11.700 USD</h5>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-12">
                                                <p><span className="square red"></span>Credit Due</p>
                                            </div>
                                            <div className="col-12">
                                                <h5>1.100 USD</h5>
                                            </div>
                                        </div>
                                        <Button className="btn-submit btn-block">Increase credit limit</Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xl-5 px-2 mb-3">
                        <Card className="box">
                            <Card.Header className="text-uppercase">ACCOUNT OVERVIEW</Card.Header>
                            <Card.Body>
                                <div className="row mx-n1">
                                    <div className="col-lg-3 px-1">
                                        <p>Status</p>
                                        <h6>Active</h6>
                                    </div>
                                    <div className="col-lg-3 px-1">
                                        <p>Rating</p>
                                        <div className="rating rate-light-green">B</div>
                                    </div>
                                    <div className="col-lg px-1">
                                        <p>Loan Type</p>
                                        <h6>Invoice Discounting</h6>
                                    </div>
                                </div>
                                <hr />
                                <div className="row mx-n1 mb-3">
                                    <div className="col-lg-6 px-1">
                                        <p>Total volume borrowed</p>
                                        <h6>37.200 SEK</h6>
                                    </div>
                                    <div className="col-lg-6 px-1">
                                        <p>Amount paid late</p>
                                        <h6 className="text-red">3.800 SEK</h6>
                                    </div>
                                </div>
                                <div className="row mx-n1">
                                    <div className="col-lg-6 px-1">
                                        <p>Amount paid back in time</p>
                                        <h6>33.400 SEK</h6>
                                    </div>
                                    <div className="col-lg-6 px-1">
                                        <p>Average days late payment</p>
                                        <h6 className="text-red">10 days</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xl-7 px-2 mb-3">
                        <Card className="box">
                            <Card.Header className="text-uppercase">CREDIT DUE</Card.Header>
                            <Card.Body>
                                <ColumnChart data={{value, colors, xAxisValues}}/>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xl-5 px-2 mb-3">
                        <Card className="box">
                            <Card.Header className="text-uppercase">MY INVOICES</Card.Header>
                            <Card.Body>
                                <div className="row mx-n1">
                                    <div className="col-lg-4 px-1">
                                        <p>Offers Available</p>
                                        <div className="d-flex align-items-center">
                                            <div className="status dot-yellow mr-2"></div>
                                            <h6>2</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-auto px-1">
                                        <p>Uploaded invoices</p>
                                        <div className="d-flex align-items-center">
                                            <div className="status dot-gray mr-2"></div>
                                            <h6>0</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 px-1"></div>
                                </div>
                                <hr />
                                <div className="row mx-n1">
                                    <div className="col-lg-4 px-1">
                                        <p>Active</p>
                                        <div className="d-flex align-items-center">
                                            <div className="status dot-green mr-2"></div>
                                            <h6>21</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 px-1">
                                        <p>Financed</p>
                                        <div className="d-flex align-items-center">
                                            <div className="status dot-blue mr-2"></div>
                                            <h6>34</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 px-1">
                                        <p>Rejected</p>
                                        <div className="d-flex align-items-center">
                                            <div className="status dot-red mr-2"></div>
                                            <h6>23</h6>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <Link className="btn-submit btn-block btn text-white" to={'/borrower/invoice/add'}>
                                    Add Invoices
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <Footer history={props.history}/>
            </div>
        </div>
    );
};

export default BorrowerDashboardWrapper;