import React, { useState, useEffect } from "react";
import {useForm} from "react-hook-form/dist/index";
import axios from 'axios';

import Sidebar from "../components/layout/sidebar/Sidebar";
import RegisterBorrowerWrapper from "../components/layout/wrapper/RegisterBorrowerWrapper";

import {progressBar} from "../helper";
import config from "../config";

function RegisterBorrower(props) {

    const [getCurrentStep, setCurrentStep] = useState(0);
    const [getCompletedSteps, setCompletedSteps] = useState([]);
    const [getBorrowerData, setBorrowerData] = useState({});
    const [getPercent, setPercent] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [isCompleteRegistration, setCompleteRegistration] = useState(false);

    const onInputchange = (event) => {

        if (event.target.type !== 'checkbox'|| event.target.type !== 'radio') {
            if (event.target.value.length > 0) {
                event.target.previousSibling.className = 'show';
                event.target.parentElement.className += ' has-success';
            } else {
                event.target.previousSibling.classList.remove("show");
                event.target.parentElement.classList.remove("has-success");
            }
        }

        setBorrowerData({...getBorrowerData, [event.target.name]: event.target.value})
    };

    /* Form`s config */
    const {
        register: registerUserAccount,
        handleSubmit: handleSubmitUserAccount,
        watch: watchUserAccount,
        errors: errorsUserAccount
    } = useForm();
    const {
        register: registerCompanyInfo,
        handleSubmit: handleSubmitCompanyInfo,
        watch: watchCompanyInfo,
        errors: errorsCompanyInfo
    } = useForm();
    const {
        register: registerKYC,
        handleSubmit: handleSubmitKYC,
        watch: watchKYC,
        errors: errorsKYC,
    } = useForm();


    /* User Account submit form */
    const onSubmitUserAccount = () => {

        if (!getCompletedSteps.includes(1)) {
            setCompletedSteps([...getCompletedSteps, 1]);
        }

        setCurrentStep(2);
        progressBar();
    };

    /* Company Information submit form */
    const onSubmitCompanyInfo = () => {

        if (!getCompletedSteps.includes(2)) {
            setCompletedSteps([...getCompletedSteps, 2]);
        }

        setCurrentStep(3);
        progressBar();
    };

    /* KYC submit form */
    const onSubmitKYC = (data) => {

        const questionsAnswers = {
            companies: {
                individuals: data.individuals,
                smallAndMedium: data.smallAndMedium,
                large: data.large,
            },
            revenue: data.revenue,
            typeOfCompany: data.typeOfCompany,
            startFinancing: data.startFinancing
        };

        if (!getCompletedSteps.includes(3)) {
            setCompletedSteps([...getCompletedSteps, 3]);
        }

        setCurrentStep(4);
        setBorrowerData({...getBorrowerData, questionsAnswers});
    };

    /* Email Verification confirmation */
    const submitEmailVerification = () => {
        setLoading(true);
        setCompletedSteps([1,2,3,4]);
        setPercent(getCurrentStep * 25);
        setCompleteRegistration(true)
    };

    /* Form`s props */
    const formUserAccount = {registerUserAccount, handleSubmitUserAccount, watchUserAccount, errorsUserAccount, onSubmitUserAccount};
    const formCompanyInfo = {registerCompanyInfo, handleSubmitCompanyInfo, watchCompanyInfo, errorsCompanyInfo, onSubmitCompanyInfo};
    const formKYC = {registerKYC, handleSubmitKYC, watchKYC, errorsKYC, onSubmitKYC};

    const requirement = () => {
        localStorage.setItem('requirement', 'true');
        setCurrentStep(1);
    };

    useEffect(function () {
        const localStorageRequirement = localStorage.getItem('requirement');

        if (localStorageRequirement === 'true' && getCurrentStep === 0) {
            setCurrentStep(1);
        }

    }, [getCurrentStep]);

    useEffect(function () {

        if (isCompleteRegistration) {
            setTimeout(function () {
                axios.post('/api/v1/register', getBorrowerData, { headers: {'Content-Type': 'application/json'}})
                    .then(function (response) {
                        setLoading(false);
                        setCompleteRegistration(false);
                        setCompletedSteps([]);
                        setBorrowerData({});
                        setPercent(0);
                        setCurrentStep(0);
                        localStorage.removeItem('requirement');

                        console.log(response)
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .finally(function () {
                        props.history.push('/');
                    });
            }, 2000);
        }

    }, [
        isCompleteRegistration,
        getBorrowerData,
        props.history
    ]);

    useEffect(function () {

        if (getCompletedSteps.length > 0) {
            setPercent(getCompletedSteps[getCompletedSteps.length - 1] * 25);
        }

    }, [getCompletedSteps]);

    return (
        <div>
            <Sidebar
                visibility={true}
                percent={getPercent}
                history={props.history}
                menu={config.infoSidebarMenu()}
            />
            <RegisterBorrowerWrapper
                history={props.history}
                userData={getBorrowerData}
                progressBarData={{currentStep: getCurrentStep, completedSteps: getCompletedSteps}}
                form={
                    {
                        userAccount: formUserAccount,
                        companyInfo: formCompanyInfo,
                        kyc: formKYC,
                        emailVerification: submitEmailVerification
                    }
                }
                formStep={{current: getCurrentStep, completed: getCompletedSteps}}
                requirement={requirement}
                isLoading={isLoading}
                setCurrentStep={setCurrentStep}
                onInputchange={onInputchange}
            />
        </div>
    );
}

export default RegisterBorrower;
