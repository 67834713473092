import React from 'react';
import Menu from "../menu/Menu";

const InfoSidebar = (props) => {
    return (
        <div>
            {props.visibility &&
            <div className="sidebar-title-holder">
                <hr className="hr-orange"/>
                <div className="sidebar-title text-center">
                    <h4 className="my-0 text-white font-weight-normal">Open account</h4>
                    <h5 className="my-0 text-orange font-weight-normal">in 3 minutes</h5>
                </div>
            </div>
            }

            {/*<div className="my-3">*/}
                {/*<Menu menu={props.menu} history={props.history}/>*/}
            {/*</div>*/}
        </div>
    );
};

export default InfoSidebar;