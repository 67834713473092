import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index";
import * as Icons from '@fortawesome/free-solid-svg-icons';

const NotFoundWrapper = (props) => {
    return (
        <div className="wrapper">
            <div className="wrapper-container">
                <div className="not-found d-flex align-items-center justify-content-center bg-white">
                    <div className="text-orange text-center">
                        <FontAwesomeIcon size="7x" color="#ff7001" icon={Icons.faExclamationTriangle} />
                        <h1 className="mt-5">404 Not Found</h1>
                        <h5>Page <b>"{props.history.location.pathname}"</b> does not exist.</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundWrapper;