import axios from "axios/index";
import config from "../config";

const GetKYCAction = data => ({
    type: 'GET_KYC',
    payload: data
});


const getKYC = () => dispatch => {
    axios.get(config.getUrl() + '/api/v1/kyc')
        .then(function (response) {
            dispatch(GetKYCAction(response.data.response));
        })
        .catch(function (error) {
        })
        .finally(function () {
        });
};


export default {
    getKYC
};