import React from 'react';

const Nav = (props) => {
    return (
        <div className="nav-holder">
            <h1>{props.title}</h1>
            <p className="lead">{props.lead}</p>
        </div>
    );
};

export default Nav;