import React from 'react';
import { Form, Button } from 'react-bootstrap';
import {progressBar} from "../../helper";

const CompanyInformation = (props) => {

    const country             = props.userData.hasOwnProperty('country')             ? props.userData.country             : '';
    const organisationNumber  = props.userData.hasOwnProperty('organisationNumber')  ? props.userData.organisationNumber  : '';
    const companyName         = props.userData.hasOwnProperty('companyName')         ? props.userData.companyName         : '';
    const companyPhoneNumber  = props.userData.hasOwnProperty('companyPhoneNumber')  ? props.userData.companyPhoneNumber  : '';

    const setProgressBar = () => {
        props.setCurrentStep(1);
        progressBar();
    };

    return (
        <div>
            <h4>Welcome {props.userData.firstName} {props.userData.lastName}</h4>
            <p>Let's continue by providing your company details.</p>
            <Form key={2} onSubmit={props.form.companyInfo.handleSubmitCompanyInfo(props.form.companyInfo.onSubmitCompanyInfo)}>
                <Form.Group
                    controlId="formCountry"
                    className={
                        props.form.companyInfo.errorsCompanyInfo.country
                            ? "has-error float-labels" : country !== "" ? "has-success float-labels" : "float-labels"
                    }
                >
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={(event) => props.onInputchange(event)}
                        className="custom-input"
                        name="country"
                        type="text"
                        defaultValue={country}
                        ref={props.form.companyInfo.registerCompanyInfo({ required: "select one option" })}
                    >
                        <option value="" disabled>Country</option>
                        <option value="Sweden">Sweden</option>
                    </Form.Control>
                    {props.form.companyInfo.errorsCompanyInfo.country && <Form.Text className="text-danger">This field is required!</Form.Text>}
                </Form.Group>
                <Form.Group
                    controlId="formOrg"
                    className={
                        props.form.companyInfo.errorsCompanyInfo.organisationNumber
                            ? "has-error float-labels" : organisationNumber !== "" ? "has-success float-labels" : "float-labels"
                    }
                >
                    <Form.Label>Organisation Number</Form.Label>
                    <Form.Control
                        onChange={(event) => props.onInputchange(event)}
                        className="custom-input"
                        name="organisationNumber"
                        type="text"
                        maxLength="10"
                        placeholder="Organisation Number"
                        defaultValue={organisationNumber}
                        ref={props.form.companyInfo.registerCompanyInfo({
                            required: "select one option",
                            maxLength: 10
                        })}
                    />
                    {props.form.companyInfo.errorsCompanyInfo.organisationNumber && <Form.Text className="text-danger">This field is required!</Form.Text>}
                </Form.Group>
                <Form.Group
                    controlId="formCompanyName"
                    className={
                        props.form.companyInfo.errorsCompanyInfo.companyName
                            ? "has-error float-labels" : companyName !== "" ? "has-success float-labels" : "float-labels"
                    }
                >
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                        onChange={(event) => props.onInputchange(event)}
                        className="custom-input"
                        name="companyName"
                        type="text"
                        defaultValue={companyName}
                        placeholder="Company Name"
                        ref={props.form.companyInfo.registerCompanyInfo({ required: true })}
                    />
                    {props.form.companyInfo.errorsCompanyInfo.companyName && <Form.Text className="text-danger">This field is required!</Form.Text>}
                </Form.Group>
                <Form.Group
                    controlId="formPhoneNumber"
                    className={
                        props.form.companyInfo.errorsCompanyInfo.companyPhoneNumber
                            ? "has-error float-labels" : companyPhoneNumber !== "" ? "has-success float-labels" : "float-labels"
                    }
                >
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        onChange={(event) => props.onInputchange(event)}
                        className="custom-input"
                        name="companyPhoneNumber"
                        type="tel"
                        defaultValue={companyPhoneNumber}
                        placeholder="Phone Number"
                        maxLength="10"
                        ref={props.form.companyInfo.registerCompanyInfo({
                            required: true,
                            maxLength: 10
                        })}
                    />
                    {props.form.companyInfo.errorsCompanyInfo.companyPhoneNumber && <Form.Text className="text-danger">This field is required!</Form.Text>}
                </Form.Group>
                <Button className="btn-submit btn-block" type="submit">Save and continue</Button>
                <div className="text-center mt-3">
                    <Button onClick={() => setProgressBar()} className="btn-back">Go back</Button>
                </div>
            </Form>
        </div>
    );
};

export default CompanyInformation;