import React from 'react';
import ReactApexCharts from 'react-apexcharts';
import numeral from 'numeral';

const ColumnChart = (props) => {

    const colors = props.data.colors;

    const series = [{
        data: props.data.value
    }];

    const options = {
        chart: {
            height: 350,
            type: 'bar',
            events: {
                click: function(chart, w, e) {
                    // console.log(chart, w, e)
                }
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: props.data.xAxisValues,
            labels: {
                style: {
                    colors: '#7c7c7c',
                    fontSize: '12px'
                }
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#7c7c7c',
                    fontSize: '12px'
                },
                formatter: function (value) {
                    return numeral(value).format('0,0');
                }
            },
        }
    };


    return  (
        <div>
            <ReactApexCharts options={options} series={series} type="bar" height={250} />
        </div>
    )
};

export default ColumnChart;