import React from "react";

import Sidebar from "../components/layout/sidebar/Sidebar";
import HelpCenterWrapper from "../components/layout/wrapper/HelpCenterWrapper";
import config from "../config";

function RegisterBorrower(props) {
    return (
        <div>
            <Sidebar
                visibility={false}
                percent={0}
                history={props.history}
                menu={config.infoSidebarMenu()}
            />
            <HelpCenterWrapper history={props.history} />
        </div>
    );
}

export default RegisterBorrower;
