import {combineReducers} from 'redux';

import userReducer from "./userReducer";
import invoiceReducer from "./invoiceReducer";
import kycReducer from "./kycReducer";

const rootReducer = combineReducers({
    user: userReducer,
    invoices: invoiceReducer,
    kyc: kycReducer
});

export default rootReducer;