import React from 'react';
import config from "../../../config";

import ProgressStepBar from "../nav/ProgressStepBar";
import Nav from "../nav/Nav";
import Footer from "../Footer";
import UserAccount from "../../register/UserAccount";
import CompanyInformation from "../../register/CompanyInformation";
import KYC from "../../register/KYC";
import EmailVerification from "../../register/EmailVerification";

import '../../../css/register.css';

const RegisterWrapper = (props) => {

    const steps = (step) => {
        switch (step.current) {
            case 1:
                return <UserAccount
                    form={props.form}
                    userData={props.userData}
                    onInputchange={props.onInputchange}
                    isLoading={props.isLoading}
                />;
            case 2:
                return <CompanyInformation
                    form={props.form}
                    userData={props.userData}
                    onInputchange={props.onInputchange}
                    setCurrentStep={props.setCurrentStep}
                />;
            case 3:
                return <KYC
                    form={props.form}
                    userData={props.userData}
                    onInputchange={props.onInputchange}
                    setCurrentStep={props.setCurrentStep}
                    isLoading={props.isLoading}
                />;
            case 4:
                return <EmailVerification
                    userData={props.userData}
                    form={props.form}
                    isLoading={props.isLoading}
                />;
            default:
                return false;
        }
    };

    return (
        <div className="wrapper">
            <div className="wrapper-container">
                <Nav
                    title="ACCOUNT SET UP - BORROWER"
                    lead=""
                />
                <ProgressStepBar
                    config={config.borrowerProgressBarConfig()}
                    progressBarData={props.progressBarData}
                />

                <div className="register-wrapper">
                    {steps(props.formStep)}
                </div>

                <Footer history={props.history}/>
            </div>
        </div>
    );
};

export default RegisterWrapper;