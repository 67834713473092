import React, {useEffect} from "react";
import { Route, Redirect } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import userActions from "./actions/user";
import Loader from "./components/layout/Loader";
import config from "./config";

function PrivateRoute({ children, ...rest }) {
    // const user = useSelector(state => state.user);
    // const dispatch = useDispatch();

    // useEffect(function () {
    //     dispatch(userActions.initUser());
    // }, []);

    const user = {
        "lastName": "Jovanovic",
        "email": "test@test.com",
        "firstName": "Marko",
        "verification": false
    };

    if (Object.keys(user).length === 0) {
        return <Loader/>
    }


    if (user.logged) {
        if (user.verification) {
            return (
                <Route
                    {...rest}
                    render={() => children}
                />
            );
        } else {
            return (
                <Route
                    {...rest}
                    render={({ location }) =>
                        <Redirect
                            to={{
                                pathname: "/borrower/verification",
                                state: { from: location }
                            }}
                        />
                    }
                />
            );
        }
    } else {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    // /register is temporary route. The real route should be /login
                    <Redirect
                        to={{
                            pathname: "/register",
                            state: { from: location }
                        }}
                    />
                }
            />
        );
    }
}

export default PrivateRoute;